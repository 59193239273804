<template>
  <div>
    <v-card
      v-if="procedureData"
      class="pa-2"
    >
      <v-card-title class="subheading font-weight-bold info--text">
        {{ procedureData.title }}
      </v-card-title>
      <v-card-subtitle>
        {{ procedureData.description }}
      </v-card-subtitle>
      <v-card-text>
      </v-card-text>
    </v-card>
    <v-form
      ref="form"
      @submit.prevent="saveAnswers"
    >
      <div
        v-if="loggedUser.role === 'professional'"
        class="mt-3 d-flex justify-end"
      >
        <v-btn
          color="primary"
          :loading="loading.newElement"
          @click="createNewElement"
        >
          Aggiungi elemento
        </v-btn>
      </div>
      <div
        v-if="surveyData.survey_elements"
      >
        <draggable
          v-model="surveyData.survey_elements"
          group="people"
          :options="{handle:'.drag-ok'}"
          @start="drag=true"
          @end="endDrag"
        >
          <v-row
            v-for="(element, index) in surveyData.survey_elements"
            :key="'SE_'+index"
          >
            <v-col
              cols="12"
            >
              <v-card
                class="mt-3 d-flex flex-column"
                height="100%"
              >
                <v-card-title>
                  Domanda {{ index + 1 }}
                  <v-spacer />
                  <v-icon
                    v-if="loggedUser.role === 'professional'"
                    class="drag-ok"
                    right
                  >
                    mdi-drag
                  </v-icon>
                </v-card-title>
                <v-card-text>
                  <v-text-field
                    v-model="element.title"
                    label="Domanda"
                    outlined
                    dense
                    :readonly="loggedUser.role !== 'professional'"
                    @input="setAsUpdated(index)"
                  />
                  <v-select
                    v-if="loggedUser.role === 'professional'"
                    v-model="element.element_type"
                    :items="elementTypes"
                    item-text="label"
                    item-value="value"
                    label="Tipo di risposta"
                    outlined
                    dense
                    @change="setAsUpdated(index)"
                  />
                  <v-spacer />
                  <v-textarea
                    v-if="element.element_type === 'open'"
                    v-model="answers_open[element.id]"
                    :name="'answer_'+index"
                    label="Risposta"
                    :readonly="loggedUser.role === 'professional'"
                    outlined
                    dense
                    @input="setAsUpdated(index)"
                  />
                  <div
                    v-if="element.element_type === 'closed'"
                  >
                    <v-radio-group
                      v-model="answers_closed[element.id]"
                    >
                      <v-container
                        v-for="sub_element in element.survey_sub_elements"
                        :key="sub_element.id"
                      >
                        <v-row
                          class=""
                        >
                          <v-col
                            v-if="loggedUser.role === 'user'"
                            cols="1"
                            class="d-flex justify-content-center align-items-center"
                          >
                            <v-radio
                              :readonly="loggedUser.role === 'professional'"
                              :value="sub_element.id"
                              :name="'answer_'+index"
                            />
                          </v-col>
                          <v-col cols="10">
                            <v-text-field
                              v-model="sub_element.sub_element_text"
                              :readonly="loggedUser.role !== 'professional'"
                              @input="setAsUpdated(index)"
                            />
                          </v-col>
                          <v-col
                            cols="1"
                            class="d-flex justify-content-center align-items-center"
                          >
                            <v-btn
                              v-if="loggedUser.role === 'professional'"
                              icon
                              @click="deleteSubElement(index, element, sub_element.id)"
                            >
                              <v-icon
                                color="error"
                              >
                                mdi-trash-can-outline
                              </v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-radio-group>
                    <v-btn
                      v-if="loggedUser.role === 'professional'"
                      color="primary"
                      @click="createNewSubElement(element, index)"
                    >
                      Aggiungi opzione
                    </v-btn>
                  </div>
                  <div
                    v-if="element.element_type === 'multiple'"
                  >
                    <v-container
                      v-for="sub_element in element.survey_sub_elements"
                      :key="sub_element.id"
                    >
                      <v-row
                        class=""
                      >
                        <v-col
                          v-if="loggedUser.role === 'user'"
                          cols="1"
                          class="d-flex justify-content-center align-items-center"
                        >
                          <v-checkbox
                            v-model="answers_multi[element.id]"
                            :name="'answer_'+index"
                            :readonly="loggedUser.role === 'professional'"
                            :value="sub_element.id"
                          />
                        </v-col>
                        <v-col cols="10">
                          <v-text-field
                            v-model="sub_element.sub_element_text"
                            :readonly="loggedUser.role !== 'professional'"
                            @input="setAsUpdated(index)"
                          />
                        </v-col>
                        <v-col
                          cols="1"
                          class="d-flex justify-content-center align-items-center"
                        >
                          <v-btn
                            v-if="loggedUser.role === 'professional'"
                            icon
                            @click="deleteSubElement(index, element, sub_element.id)"
                          >
                            <v-icon
                              color="error"
                            >
                              mdi-trash-can-outline
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-btn
                      v-if="loggedUser.role === 'professional'"
                      color="primary"
                      @click="createNewSubElement(element, index)"
                    >
                      Aggiungi opzione
                    </v-btn>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    v-if="loggedUser.role === 'professional'"
                    icon
                    :loading="loading.elements[index]"
                    @click="deleteElement(index, element)"
                  >
                    <v-icon
                      color="error"
                    >
                      mdi-trash-can-outline
                    </v-icon>
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    v-if="updated.includes(index)"
                    color="primary"
                    :loading="loading.elements[index]"
                    @click="editElement(index, element)"
                  >
                    Salva
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </draggable>
      </div>
      <div class="mt-5">
        <v-spacer />
        <v-btn
          v-if="loggedUser.role==='user'"
          color="primary"
          @click="saveAnswers"
        >
          Invia risposte
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'

export default {
  components: { draggable },
  data() {
    return {
      drag: false,
      is_show: false,
      procedureData: {},
      surveyData: {},
      updated: [],
      loading: {
        newElement: false,
        newSubElement: false,
        saveAnswers: false,
        elements: {},
      },
      elementTypes: [
        {
          label: 'Risposta aperta',
          value: 'open',
        },
        {
          label: 'Risposta chiusa',
          value: 'closed',
        },
        {
          label: 'Risposta multipla',
          value: 'multiple',
        },
      ],
      breadCrumb: [
        {
          text: 'Dashboard',
          to: '/dashboard',
        },
        {
          text: 'Le mie pratiche',
          to: '/procedures',
        },
        {
          text: 'Dettaglio pratica',
          to: `/procedure/${this.$route.params.id}/view`,
        },
        {
          text: 'Questionario',
          to: `/procedure/${this.$route.params.id}/surveys/${this.$route.params.sid}`,
        },
      ],
      answers_open: {},
      answers_closed: {},
      answers_multi: {},
      answers: {},
    }
  },
  computed: {
    ...mapGetters(['loggedUser', 'procedureList']),
  },
  watch: {
    procedureList: {
      handler() {
        this.getData()
      },
      deep: true,
    },
  },
  mounted() {
    this.$store.dispatch('setBreadCrumbLevels', this.breadCrumb)
    this.getData()
  },
  methods: {
    async buildAnswers() {
      this.procedureList.forEach(procedure => {
        procedure.surveys.forEach(survey => {
          survey.survey_elements.forEach(element => {
            if (element.element_type === 'closed') {
              if (element.active_survey_responses.length > 0) {
                this.$set(this.answers_closed, element.id, parseInt(element.active_survey_responses[0].response, 10))
              } else {
                this.$set(this.answers_closed, element.id, '')
              }
            }
            if (element.element_type === 'open') {
              if (element.active_survey_responses.length > 0) {
                this.$set(this.answers_open, element.id, element.active_survey_responses[0].response)
              } else {
                this.$set(this.answers_open, element.id, '')
              }
            }
            if (element.element_type === 'multiple') {
              if (element.active_survey_responses.length > 0) {
                const resp = element.active_survey_responses[0].response.split(',').map(Number)
                resp.shift()
                resp.pop()

                this.$set(this.answers_multi, element.id, resp)
                console.log(this.answers_multi)
              } else {
                this.$set(this.answers_multi, element.id, [])
              }
            }
          })
        })
      })
    },
    async saveAnswers() {
      this.loading.saveAnswers = true

      this.answers = {
        open: this.answers_open,
        closed: this.answers_closed,
        multiple: this.answers_multi,
      }

      try {
        await this.$api.saveSurveyResponses(this.procedureData.id, this.surveyData.id, { responses: this.answers })
        await this.$store.dispatch('set_alert', { msg: 'Questionario salvato con successo', type: 'success' })
        this.loading.saveAnswers = false
      } catch (e) {
        this.loading.saveAnswers = false
        await this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
      }
    },
    async endDrag(evt) {
      this.drag = false
      const data = {
        oldIndex: evt.oldIndex + 1,
        newIndex: evt.newIndex + 1,
      }

      const elementId = this.surveyData.survey_elements[evt.newIndex].id

      try {
        await this.$api.editSurveyElementIndex(this.procedureData.id, this.surveyData.id, elementId, data)
        await this.$store.dispatch('set_alert', { msg: 'Ordine aggiornato con successo', type: 'success' })
      } catch (e) {
        await this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
      }
    },
    async createNewElement() {
      this.loading.newElement = true
      try {
        await this.$api.createSurveyElement(this.procedureData.id, this.surveyData.id)
        await this.$store.dispatch('set_alert', { msg: 'Elemento aggiunto con successo', type: 'success' })
        await this.reloadData()
        this.loading.newElement = false
      } catch (e) {
        this.loading.newElement = false
        await this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
      }
    },
    async editElement(index, element) {
      const indexOf = this.updated.indexOf(index)
      this.loading.elements[index] = true
      try {
        await this.$api.editSurveyElement(this.procedureData.id, this.surveyData.id, element.id, element)
        await this.$store.dispatch('set_alert', { msg: 'Elemento aggiornato con successo', type: 'success' })
        await this.reloadData()

        if (indexOf > -1) {
          this.updated.splice(indexOf, 1)
        }
        this.loading.elements[index] = false
      } catch (e) {
        this.loading.elements[index] = false
        await this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
      }
    },
    async deleteElement(index, element) {
      try {
        this.loading.elements[index] = true
        await this.$api.deleteSurveyElement(this.procedureData.id, this.surveyData.id, element.id)
        await this.$store.dispatch('set_alert', { msg: 'Opzione eliminata con successo', type: 'success' })
        this.loading.elements[index] = false
        await this.reloadData()
      } catch (e) {
        this.loading.elements[index] = false
        await this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
      }
    },
    async createNewSubElement(element, index) {
      const indexOf = this.updated.indexOf(index)
      if (indexOf > -1) {
        await this.editElement(index, element)
      }
      this.loading.newSubElement = true
      try {
        await this.$api.createSurveySubElement(this.procedureData.id, this.surveyData.id, element.id)
        await this.$store.dispatch('set_alert', { msg: 'Opzione aggiunta con successo', type: 'success' })
        await this.reloadData()
        this.loading.newSubElement = false
      } catch (e) {
        this.loading.newSubElement = false
        await this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
      }
    },
    async deleteSubElement(index, element, seid) {
      try {
        this.loading.elements[index] = true
        await this.$api.deleteSurveySubElement(this.procedureData.id, this.surveyData.id, element.id, seid)
        await this.$store.dispatch('set_alert', { msg: 'Elemento eliminato con successo', type: 'success' })
        this.loading.elements[index] = false
        await this.reloadData()
      } catch (e) {
        this.loading.elements[index] = false
        await this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
      }
    },
    setAsUpdated(index) {
      if (!this.updated.includes(index)) {
        this.updated.push(index)
        this.$set(this.loading.elements, index, false)
      }
    },
    async reloadData() {
      const procedureId = parseInt(this.$route.params.id, 10)
      await this.$store.dispatch('getProcedure', procedureId)
      await this.getData()
    },
    async getData() {
      const procedureId = parseInt(this.$route.params.id, 10)
      this.procedureData = await this.procedureList.find(item => item.id === procedureId)

      const surveyId = parseInt(this.$route.params.sid, 10)
      this.surveyData = await this.procedureData.surveys.find(item => item.id === surveyId)

      await this.buildAnswers()
    },
    setLoading(idx) {
      if (this.loading[idx] === undefined) {
        this.$set(this.loading, idx, true)
      } else {
        this.loading[idx] = !this.loading[idx]
      }
    },
  },
}
</script>

<style scoped>

</style>
